<script>
    export default {
        name: "Refresh",
        beforeRouteEnter(to, from, next) {
            next(vm => {vm.$router.replace({path: from.fullPath, query:from.query})})
        },
        render: function(h) {
            return h()
        }
    }
</script>